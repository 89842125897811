<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1>Estimates</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/job-numbers/create">
          <button class="button expanded">
            Add Job
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h4>Filter by:</h4>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-3">
        <label>Client</label>
        <select v-model="searchClient">
          <option
            v-for="(client, index) in clientList"
            :key="`c_${index}`"
            :value="client.id">{{ client.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Brand</label>
        <select v-model="searchBrand">
          <option
            v-for="(brand, index) in brandList"
            :key="`br_${index}`"
            :value="brand.id">{{ brand.name }}</option>
        </select>
      </div>
      <div class="cell small-12 medium-3">
        <label>Account Handler</label>
        <select v-model="searchAccountHandler">
          <option
            v-for="(handler, index) in accountHandlerList"
            :key="`h_${index}`"
            :value="handler.id">{{ handler.name }}</option>
        </select>
      </div>
    </div>
    <div class="grid-x grid-padding-x pt-5">
      <div class="cell small-12">
        <table>
          <thead>
            <tr>
              <th>Job No</th>
              <th>Client</th>
              <th>Brand</th>
              <th>Job Name</th>
              <th>Added by</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(estimate, index) in estimates" :key="index">
              <td>{{ estimate.job_number.job_number }}</td>
              <td>{{ estimate.client.name }}</td>
              <td>{{ estimate.brand.name }}</td>
              <td>{{ estimate.job_name }}</td>
              <td>{{ estimate.user.first_name }} {{ estimate.user.last_name }}</td>
              <td class="text-right">
                <router-link
                  class="button small"
                  :to="`/estimates/add-lines/${estimate.id}`">
                  Add lines
                </router-link>
                &nbsp;
                <router-link
                  class="button small"
                  :to="`/estimates/view/${estimate.id}`">
                  View
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'EstimatesIndex',
  data() {
    return {
      accountHandlerList: [],
      brandList: [],
      clientList: [],
      estimates: [],
      searchAccountHandler: 0,
      searchBrand: 0,
      searchClient: 0,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    searchAccountHandler() {
      this.getEstimates();
    },
    searchBrand() {
      this.getEstimates();
    },
    searchClient(value) {
      const client = this.clientList.filter((clientVal) => clientVal.id === value)[0];
      this.brandList = client.brands;
      this.searchBrand = 0;
      this.getEstimates();
    },
  },
  methods: {
    getEstimates() {
      const postData = { search: {} };
      postData.search.accountHandler = this.searchAccountHandler;
      postData.search.brand = this.searchBrand;
      postData.search.client = this.searchClient;
      axios.post(`/estimates/getAll.json?token=${this.token}`, postData)
        .then((response) => {
          this.estimates = response.data.estimates;
        })
        .catch(() => {
          this.failed = true;
        });
    },
    getSearchLists() {
      axios.get(`/searches/getJobSearchList/${this.page}.json?token=${this.token}`)
        .then((response) => {
          this.accountHandlerList = response.data.accountHandlers;
          this.clientList = response.data.clients;
        })
        .catch(() => {
          this.failed = true;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getSearchLists();
    }, 100);
  },
};
</script>
